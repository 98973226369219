import axios from 'axios';

const defaults = {
  baseURL: '/api',
}

const instance = axios.create(defaults);

export const authorizedInstance = axios.create(defaults);
authorizedInstance.interceptors.request.use(config => {
  return Object.assign({}, config, {
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('session'),
    },
  });
}, error => {
  return Promise.reject(error);
});

function authorized() {
  return authorizedInstance;
}

export function chat_create(name) {
  return authorized().post('chats', {
    name,
  });
}

export function chat_group_info_get(chatId) {
  return authorized().get(`chats/chat/${chatId}`);
}

export function chat_group_join(chatId) {
  return authorized().post(`chats/chat/${chatId}/join`);
}

export function chat_group_kick_member(chatId, accountId) {
  return authorized().post(`chats/chat/${chatId}/kick`, {
    account_id: accountId,
  });
}

export function chat_group_messages_get(chatId, params) {
  return authorized().get(`chats/chat/${chatId}/messages`, {params: params});
}

export function chat_group_part(chatId) {
  return authorized().post(`chats/chat/${chatId}/part`);
}

export function chat_group_rename(chatId, newName) {
  return authorized().post(`chats/chat/${chatId}/rename`, {
    name: newName,
  });
}

export function chat_group_set_admin(chatId, accountId, admin) {
  return authorized().post(`chats/chat/${chatId}/admin`, {
    account_id: accountId,
    admin: admin,
  });
}

export function chat_list() {
  return authorized().get('chats');
}

export function chat_private_get(accountId) {
  return authorized().get('chats/private/' + accountId);
}

export function confirm(code) {
  return instance.post('account/email/confirm', {
    code: code,
  });
}

export function coords_post(accuracy, latitude, longitude, timestamp) {
  return authorized().post('coords', {
    accuracy,
    latitude,
    longitude,
    timestamp,
  });
}

export function email_add(email) {
  return authorized().post('account/email/add', {
    email: email,
  });
}

export function email_delete(email) {
  return authorized().post('account/email/delete', {
    email: email,
  });
}

export function email_list() {
  return authorized().get('account/email');
}

export function gdpr_delete() {
  return authorized().post('gdpr/request-delete');
};

export function image_me() {
  return authorized().get('image/me');
};

export function image_me_delete(image_id) {
  return authorized().delete(`image/me/${image_id}`);
};

export function image_me_upload(file) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return authorized().post('image/me', formData, config);
};

export function like(account_id, like) {
  return authorized().post('like', {
    account_id,
    like,
  });
};

export function login(email, password) {
  return instance.post('account/session/login', {
    email: email,
    password: password,
  });
}

export function password_change(oldpassword, newpassword) {
  return authorized().post('account/password/change', {
    oldpassword: oldpassword,
    newpassword: newpassword,
  });
}

export function password_request_reset(email) {
  return instance.post('account/password/request-reset', {
    email: email,
  });
}

export function password_reset(code, newpassword) {
  return instance.post('account/password/reset', {
    code: code,
    newpassword: newpassword,
  });
}

export function profile_get() {
  return authorized().get('profile');
}

export function profile_post(dob, gender, name, text) {
  return authorized().post('profile', {
    dob: dob,
    gender: gender,
    name: name,
    text: text,
  });
}

export function profile_public(account_id) {
  return authorized().get(`profile/${account_id}`);
}

export function register(email, password) {
  return instance.post('account/register', {
    email: email,
    password: password,
  });
}

export function search({ distance, gender, maxAge, minAge, status }) {
  return authorized().post('search', {
    distance,
    gender,
    max_age: maxAge,
    min_age: minAge,
    status,
  });
}

export function session_delete(session_id) {
  return authorized().post('account/session/delete', {
    session: session_id,
  });
}

export function session_list() {
  return authorized().get('account/session');
}

export function session_logout() {
  return authorized().post('account/session/logout');
}
