import React from 'react';

import UserBrowsing from './UserBrowsing';
import WhenLoggedIn from './WhenLoggedIn';

function App() {
  return (
    <div>
      <WhenLoggedIn>
        <UserBrowsing />
      </WhenLoggedIn>
    </div>
  );
}

export default App;
