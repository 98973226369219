import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RootContext } from './RootContextProvider';

export default function GDPRPortability() {
  const { session } = React.useContext(RootContext);

  return (
    <React.Fragment>
      <div>
        <FormattedMessage
          id="gdprportability.info"
          defaultMessage="You may export a copy of your personal data. You are responsible for storing your data securely once you download it."
        />
      </div>

      <form action="/api/gdpr/portability" method="post">
        <input type="hidden" name="session" value={session} />
        <button className="btn btn-primary">
          <FormattedMessage
            id="gdprportability.download"
            defaultMessage="Download data"
          />
        </button>
      </form>
    </React.Fragment>
  );
};
