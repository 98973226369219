import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import * as API from './API';
import { RootContext } from './RootContextProvider';

export default function LoginForm() {
  const email = React.useRef();
  const [failure, setFailure] = React.useState(false);
  const password = React.useRef();
  const [redirect, setRedirect] = React.useState(false)
  const { setSession } = React.useContext(RootContext);

  const handleSubmit = (event) => {
    setFailure(false);
    API.login(email.current.value, password.current.value)
      .then(response => {
        localStorage.setItem('session', response.data.token);
        setSession(response.data.token);
        setRedirect(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          setFailure(true);
        }
      });
    event.preventDefault();
  }

  if (redirect) {
    return (
      <Redirect to="/" />
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <h1>Log in</h1>

      <div className="form-group row">
        <label className="col-form-label col-sm-3" htmlFor="email">
          Email:
        </label>
        <div className="col-sm-3">
          <input className="form-control" id="email" type="text" ref={email} />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-3" htmlFor="password">
          Password (<Link to="/password_request_reset">forgot?</Link>):
        </label>
        <div className="col-sm-3">
          <input className="form-control" id="password" type="password" ref={password} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-10">
          <button className="btn btn-primary">Log in</button>
        </div>
      </div>
      {failure &&
      <div className="alert alert-warning" role="alert">
        Invalid email or password. Please try again or reset your password.
      </div>
      }
    </form>
  );
};
