import React from 'react';

import { authorizedInstance } from './API';

export const RootContext = React.createContext();

export function RootContextProvider({ children }) {
  const prevSession = localStorage.getItem('session');
  const [session, setSession] = React.useState(prevSession);

  React.useEffect(
    () => {
      if (session !== null) {
        localStorage.setItem('session', session);
      } else {
        localStorage.removeItem('session');
      }
    },
    [session]
  );

  const installInterceptor = () => {
    const interceptor = authorizedInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setSession(null);
        }
        return Promise.reject(error);
      },
    );
    return interceptor;
  };

  // Install interceptor immediately without using useEffect, because the run
  // order of useEffect may be too late (eg. when another useEffect has already
  // created axios request)
  if (authorizedInstance.interceptors.response.handlers.length === 0) {
    installInterceptor();
  }

  const defaultContext = {
    session,
    setSession,
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};
