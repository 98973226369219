import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFemale,
  faMale,
} from '@fortawesome/free-solid-svg-icons';

export default function AccountInline({ account }) {
  return (
    <span>
      <Link to={`/profile/${account.account_id}`}>
        {account.name || account.account_id}
      </Link>
      &nbsp;
      {account.gender === 'F' &&
      <FontAwesomeIcon icon={faFemale} />
      }
      {account.gender === 'M' &&
      <FontAwesomeIcon icon={faMale} />
      }
    </span>
  );
}
