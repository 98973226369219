import React from 'react';

import * as API from './API';

export default function PasswordRequestResetPage() {
  const [email, setEmail] = React.useState('');
  const [sent, setSent] = React.useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSent(false);
    API.password_request_reset(email).then(response => {
      setSent(true);
      setEmail('');
    });
  };

  return (
    <React.Fragment>
      <h1>Reset your password</h1>
      <p>Enter your email address to receive a link to reset your password.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            autoFocus
            className="form-control"
            id="email"
            onChange={handleChange}
            placeholder="Email"
            required
            type="email"
            value={email}
          />
        </div>
        <button className="btn btn-primary">Send password reset email</button>
      </form>
      {sent &&
      <div className="alert alert-info" role="alert">
        A password reset email has been sent to the provided address.
      </div>
      }
    </React.Fragment>
  );
};
