import React from 'react';

import * as AccountNamesService from './AccountNamesService';
import *  as API from './API';
import { RootContext } from './RootContextProvider';

export const ProfileContext = React.createContext();

export function ProfileContextProvider({ children }) {
  const { session } = React.useContext(RootContext);
  const [profile, setProfile] = React.useState(null);

  React.useEffect(() => {
    if (session) {
      API.profile_get().then(response => {
        setProfile(response.data);
        AccountNamesService.setAccount(response.data.id, response.data);
      });
    } else {
      setProfile(null);
    }
  }, [session]);

  const profileContext = {
    profile,
    setProfile,
  };

  return (
    <ProfileContext.Provider value={profileContext}>
      {children}
    </ProfileContext.Provider>
  );
}
