import React from 'react';

import * as API from './API';
import Title from './Title';
import UserProfileActionBar from './UserProfileActionBar';
import UserProfileCard from './UserProfileCard';

export default function UserProfile({ history, match }) {
  const accountId = parseInt(match.params.accountId, 10);

  const [profile, setProfile] = React.useState(null);

  React.useEffect(() => {
    API.profile_public(accountId)
      .then(response => {
        const prof = Object.assign({}, {id: accountId}, response.data);
        setProfile(prof);
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          history.push('/');
        }
      });
  }, [accountId, history]);

  return (
    <React.Fragment>
      {profile &&
      <div className="d-flex flex-column align-items-center">
        <Title title={`Profile: ${profile.name}`} />
        <UserProfileCard profile={profile} />
        <UserProfileActionBar
          profile={profile}
        />
      </div>
      }
    </React.Fragment>
  );
}
