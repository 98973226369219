import React from 'react';

import { RootContext } from './RootContextProvider';
import * as API from './API';

export const LocationContext = React.createContext();

const options: PositionOptions = {
  enableHighAccuracy: true,
};

function UpdateLocation() {
  const promise = new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        API.coords_post(pos.coords.accuracy, pos.coords.latitude, pos.coords.longitude, pos.timestamp).then(response => {
          resolve({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        });
      }, error => {
        console.warn('Geolocation error', error);
        resolve(null);
      }, options);
    } else {
      resolve(null);
    }
  });
  return promise;
}

export function LocationContextProvider({ children }) {
  const { session } = React.useContext(RootContext);
  const [location, setLocation] = React.useState(null);

  React.useEffect(() => {
    if (session) {
      UpdateLocation().then(loc => setLocation(loc));
    } else {
      setLocation(null);
    }
  }, [session]);

  const locationContext = {
    location,
  };

  return (
    <LocationContext.Provider value={locationContext}>
      {children}
    </LocationContext.Provider>
  );
}
