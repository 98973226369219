import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faFeatherAlt,
  faIdBadge,
  faImages,
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';

import * as API from './API';
import { RootContext } from './RootContextProvider';

export default function SessionMenu() {
  const { session, setSession } = React.useContext(RootContext);

  const handleLogout = () => {
    API.session_logout().then(response => {
      setSession(null);
    });
  }

  const logged = (
    <React.Fragment>
      <li className="nav-item">
        <Link className="nav-link" to="/chats">
          <FontAwesomeIcon icon={faComments} />
          &nbsp;
          <FormattedMessage
            id="sessionmenu.chats"
            defaultMessage="Chats"
          />
        </Link>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
          <FontAwesomeIcon icon={faUserCircle} />
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/profile">
            <FontAwesomeIcon fixedWidth icon={faIdBadge} />
            &nbsp;
            <FormattedMessage
              id="sessionmenu.profile"
              defaultMessage="Profile"
            />
          </Link>
          <Link className="dropdown-item" to="/images">
            <FontAwesomeIcon fixedWidth icon={faImages} />
            &nbsp;
            <FormattedMessage
              id="sessionmenu.images"
              defaultMessage="Images"
            />
          </Link>
          <Link className="dropdown-item" to="/account">
            <FontAwesomeIcon fixedWidth icon={faUserCog} />
            &nbsp;
            <FormattedMessage
              id="sessionmenu.account"
              defaultMessage="Account"
            />
          </Link>
          <div className="dropdown-divider"></div>
          <button className="dropdown-item" onClick={handleLogout} type="button">
            <FontAwesomeIcon fixedWidth icon={faSignOutAlt} />
            &nbsp;
            <FormattedMessage
              id="sessionmenu.log_out"
              defaultMessage="Log out"
            />
          </button>
        </div>
      </li>
    </React.Fragment>
  );

  const notlogged = (
    <React.Fragment>
      <li className="nav-item">
        <Link className="nav-link text-white bg-primary" to="/register"><FontAwesomeIcon fixedWidth icon={faFeatherAlt} /> Register</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/login"><FontAwesomeIcon fixedWidth icon={faSignInAlt} /> Log in</Link>
      </li>
    </React.Fragment>
  );

  return session !== null ? logged : notlogged;
};
