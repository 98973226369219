import React from 'react';
import { useIntl } from 'react-intl';

import { client } from './Websocket';

export function NotificationComponent({ history }) {
  const intl = useIntl();

  // Request permission on initial render
  React.useEffect(() => {
    if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  }, []);

  // Subscribe to client.allMessages and show notification if message is to a chat that is not open
  React.useEffect(() => {
    const subscription = client.allMessages.subscribe(msg => {
      const targetPathname = '/chats/' + (msg.private_chat_account_id ? `private/${msg.private_chat_account_id}` : `public/${msg.chat_id}`);

      if (window.location.pathname !== targetPathname) {
        const notification = new Notification(intl.formatMessage({id: 'notificationcomponent.new_message', defaultMessage: 'New Message'}), {
          body: `${msg.account_id}: ${msg.text}`,
        });
        notification.onclick = (event) => {
          // Open the chat with the notified message, if it's not already open
          if (window.location.pathname !== targetPathname) {
            history.push(targetPathname);
          }
        };
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [history, intl]);

  // Render nothing
  return null;
}
