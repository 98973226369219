import React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl';

import * as API from './API';
import Title from './Title';

export default function ImagesPage() {
  const fileInputRef = React.useRef();
  const [images, setImages] = React.useState([]);

  const intl = useIntl();

  const deleteImage = (image_id) => {
    API.image_me_delete(image_id).then(response => {
      setImages(images.filter((i) => i.image_id !== image_id));
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    API.image_me_upload(fileInputRef.current.files[0]).then(response => {
      setImages([response.data].concat(images));
    });
  };

  React.useEffect(() => {
    API.image_me().then(response => {
      setImages(response.data);
    });
  }, []);

  return (
    <Title title={intl.formatMessage({id: 'images.title', defaultMessage: 'Images'})}>
      <h1>
        <FormattedMessage
          id="images.title"
          defaultMessage="Images"
        />
      </h1>

      <div className="row">
        {images.map((image) =>
        <div className="col-sm-4" key={image.image_id}>
          <div className="card">
            {/* eslint-disable-next-line */}
            <img className="card-img-top" src={'/images/th/640x640/' + image.filename} />
            <div className="card-body">
              <p className="card-text">
                <FormattedDate
                  value={new Date(image.ts_create)}
                />
                &nbsp;
                <FormattedTime
                  value={new Date(image.ts_create)}
                />
              </p>
              <button className="btn btn-danger" onClick={(e) => deleteImage(image.image_id)}>
                <FormattedMessage
                  id="images.delete"
                  defaultMessage="Delete"
                />
              </button>
            </div>
          </div>
        </div>
        )}
      </div>

      <h2>
        <FormattedMessage
          id="images.upload_new_image"
          defaultMessage="Upload new image"
        />
      </h2>

      <form onSubmit={handleSubmit}>
        <input
          accept="image/jpeg, image/png"
          className="form-control-file"
          ref={fileInputRef}
          type="file"
        />
        <button className="btn btn-primary">
          <FormattedMessage
            id="images.upload"
            defaultMessage="Upload"
          />
        </button>
      </form>
    </Title>
  );
};
