import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import AccountPage from './AccountPage';
import App from './App';
import ChatGroup from './ChatGroup';
import ChatPrivate from './ChatPrivate';
import ChatsPage from './ChatsPage';
import ConfirmPage from './ConfirmPage';
import EmailsForm from './EmailsForm';
import ImagesPage from './ImagesPage';
import LocalizationProvider from './intl/LocalizationProvider';
import { LocationContextProvider } from './LocationContextProvider';
import LoginForm from './LoginForm';
import NavBar from './NavBar';
import { NotificationComponent } from './NotificationComponent';
import PasswordResetPage from './PasswordResetPage';
import PasswordRequestResetPage from './PasswordRequestResetPage';
import PrivateRoute from './PrivateRoute';
import { ProfileContextProvider } from './ProfileContextProvider';
import ProfilePage from './ProfilePage';
import ProfileWarning from './ProfileWarning';
import RegisterPage from './RegisterPage';
import { RootContextProvider } from './RootContextProvider';
import SessionsForm from './SessionsForm';
import Title from './Title';
import UserProfile from './UserProfile';
import { WebsocketComponent } from './Websocket';

function AppRouter() {
  return (
    <RootContextProvider>
      <LocationContextProvider>
        <ProfileContextProvider>
          <WebsocketComponent />
          <LocalizationProvider>
            <Title title="Pilke">
              <Router>
                <div className="container">
                  <NavBar />
                  <ProfileWarning />

                  <Route path="/" exact component={App} />
                  <Route component={NotificationComponent} />
                  <PrivateRoute path="/account" component={AccountPage} />
                  <PrivateRoute path="/chats" component={ChatsPage} exact />
                  <PrivateRoute path="/chats/private/:accountId" component={ChatPrivate} />
                  <PrivateRoute path="/chats/public/:chatId" component={ChatGroup} />
                  <Route path="/confirm/:code" component={ConfirmPage} />
                  <PrivateRoute path="/emails" component={EmailsForm} />
                  <PrivateRoute path="/images" component={ImagesPage} />
                  <Route path="/login" component={LoginForm} />
                  <Route path="/password_reset/:code" component={PasswordResetPage} />
                  <Route path="/password_request_reset" component={PasswordRequestResetPage} />
                  <PrivateRoute path="/profile" exact component={ProfilePage} />
                  <PrivateRoute path="/profile/:accountId" component={UserProfile} />
                  <Route path="/register" component={RegisterPage} />
                  <PrivateRoute path="/sessions" component={SessionsForm} />
                </div>
              </Router>
            </Title>
          </LocalizationProvider>
        </ProfileContextProvider>
      </LocationContextProvider>
    </RootContextProvider>
  );
}

export default AppRouter;
