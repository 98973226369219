import React from 'react';
import Linkify from 'react-linkify';

import './UserText.css';

export default function UserText({ children }) {
  const componentDecorator = React.useCallback((href, text, key) => (
    <a href={href} key={key} rel="noopener noreferrer" target="_blank">{text}</a>
  ), []);

  return (
    <span className="usertext">
      <Linkify componentDecorator={componentDecorator}>
        {children}
      </Linkify>
    </span>
  );
}
