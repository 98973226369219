import React from 'react';
import { IntlProvider } from 'react-intl';

import fi from './fi';

export const LocalizationContext = React.createContext();

const availableLocales = ['en', 'fi'];

export default function LocalizationProvider({ children }) {
  let defaultLanguage = localStorage.getItem('locale') || navigator.language;
  if (!availableLocales.includes(defaultLanguage)) {
    defaultLanguage = 'en';
  }
  const [locale, setLocale] = React.useState(defaultLanguage);

  React.useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  const messages = React.useCallback(() => {
    let messages;
    switch (locale) {
      case 'fi':
        messages = fi;
        break;
      default:
        messages = {};
        break;
    }
    return messages;
  }, [locale]);

  const context = {
    locale,
    setLocale,
  };

  return (
    <IntlProvider locale={locale} key={locale} messages={messages()}>
      <LocalizationContext.Provider value={context}>
        {children}
      </LocalizationContext.Provider>
    </IntlProvider>
  );
}
