import React from 'react';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';

import * as API from './API';
import * as AccountNamesService from './AccountNamesService';
import ChatLine from './ChatLine';
import ChatMessages from './ChatMessages';
import Title from './Title';
import { client } from './Websocket';

import './ChatPrivate.css';

export default function ChatPrivate({ match }) {
  const accountId = parseInt(match.params.accountId, 10);

  const [messages, setMessages] = React.useState([]);
  const [profile, setProfile] = React.useState(null);

  const intl = useIntl();

  // Fetch profile
  React.useEffect(() => {
    API.profile_public(accountId).then(response => {
      setProfile(response.data);
      AccountNamesService.setAccount(accountId, response.data);
    });
  }, [accountId]);

  // Initial fetch of messages
  React.useEffect(() => {
    API.chat_private_get(accountId).then(response => {
      setMessages(response.data.reverse());
    });
  }, [accountId]);

  // Listen to messages from websocket client
  React.useEffect(() => {
    const subscription = client.privateChatMessages(accountId).subscribe(msg => {
      setMessages(messages => messages.concat([msg]));
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [accountId]);

  const send = React.useCallback((text) => {
    client.sendPrivateMessage(accountId, text);
  }, [accountId]);

  return (
    <div className="d-flex flex-column" style={{height: '100vh'}}>
      {profile &&
      <React.Fragment>
        <Title title={intl.formatMessage({id: 'chatprivate.title', defaultMessage: 'Chat with {name}'}, {name: profile.name})} />
        <h3>
          <FormattedMessage
            id="chatprivate.title"
            defaultMessage="Chat with {name}"
            values={{
              name: profile.name,
            }}
          />
          &nbsp;
          <small className="text-muted">{profile.age}</small>
        </h3>
        <div className="alert alert-info" role="alert">
          <blockquote className="blockquote">
            {profile.text}
          </blockquote>
        </div>
      </React.Fragment>
      }

      <div className="flex-grow-1" style={{'overflowY': 'scroll'}}>
        <ChatMessages messages={messages} />
      </div>

      <ChatLine onSend={send} />
    </div>
  );
}
