import React from 'react';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import * as API from './API';
import { ProfileContext } from './ProfileContextProvider';
import Title from './Title';

export default function ProfilePage() {
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [dob, setDob] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [name, setName] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  const [text, setText] = React.useState('');

  const { profile, setProfile } = React.useContext(ProfileContext);

  const intl = useIntl();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaved(false);
    API.profile_post(dob, gender, name, text).then(response => {
      setCanSubmit(false);
      setSaved(true);
      setProfile(response.data);
    });
  };

  const setData = (data) => {
    if (data.dob) {
      setDob(data.dob);
    }
    if (data.gender) {
      setGender(data.gender);
    }
    if (data.name) {
      setName(data.name);
    }
    if (data.text) {
      setText(data.text);
    }
  };

  // Fetch state from ProfileContextProvider
  React.useEffect(() => {
    if (profile !== null) {
      setData(profile);
    }
  }, [profile]);

  // When changes are made
  React.useEffect(() => {
    setSaved(false);
    setCanSubmit(dob !== '' && gender !== '' && name !== '');
  }, [dob, gender, name, text]);

  return (
    <Title title={intl.formatMessage({id: 'profile.title', defaultMessage: 'Profile'})}>
      <h1>
        <FormattedMessage
          id="profile.header"
          defaultMessage="Profile"
        />
      </h1>

      {profile &&
      <div>
        <FormattedMessage
          id="profile.public_profile_link"
          defaultMessage="See your <Link>public profile</Link>."
          values={{
            Link: msg => <Link to={`/profile/${profile.id}`}>{msg}</Link>
          }}
        />
      </div>
      }

      <div className="alert alert-info" role="alert">
        <FontAwesomeIcon icon={faEye} />
        &nbsp;
        <FormattedMessage
          id="profile.disclaimer_visibility"
          defaultMessage="All information you enter here is public."
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="dob">
            <FormattedMessage
              id="profile.dob"
              defaultMessage="Date of Birth"
            />
          </label>
          <input
            className="form-control"
            id="dob"
            onChange={e => setDob(e.target.value)}
            required
            type="date"
            value={dob}
          />
        </div>
        <div className="form-group">
          <label htmlFor="gender">
            <FormattedMessage
              id="profile.gender"
              defaultMessage="Gender"
            />
          </label>
          <select className="form-control" id="gender" onChange={e => setGender(e.target.value)} required value={gender}>
            <option value="">{intl.formatMessage({id: 'profile.gender_select', defaultMessage: 'Select'})}</option>
            <option value="M">{intl.formatMessage({id: 'profile.gender_male', defaultMessage: 'Male'})}</option>
            <option value="F">{intl.formatMessage({id: 'profile.gender_female', defaultMessage: 'Female'})}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">
            <FormattedMessage
              id="profile.name"
              defaultMessage="Name"
            />
          </label>
          <input
            className="form-control"
            id="name"
            maxLength="50"
            onChange={e => setName(e.target.value)}
            required
            type="text"
            value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="text">
            <FormattedMessage
              id="profile.profile_text"
              defaultMessage="Profile text"
            />
          </label>
          <textarea
            className="form-control"
            id="text"
            maxLength="500"
            onChange={e => setText(e.target.value)}
            value={text}
          />
        </div>
        <button className="btn btn-primary" disabled={!canSubmit}>
          <FormattedMessage
            id="profile.save"
            defaultMessage="Save"
          />
        </button>
      </form>

      {saved &&
      <div className="alert alert-success" role="alert">
        <FormattedMessage
          id="profile.saved"
          defaultMessage="Your profile has been saved."
        />
      </div>
      }
    </Title>
  );
};
