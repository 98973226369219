import React from 'react';

import { Helmet } from 'react-helmet';

export default function Title({ children, title }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </React.Fragment>
  );
}
