import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import * as API from './API';

export default function PasswordResetPage({ match }) {
  const code = match.params.code;

  const [invalidCode, setInvalidCode] = React.useState(false);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [redirectLogin, setRedirectLogin] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  React.useEffect(() => {
    setSubmitDisabled(password1 !== password2);
  }, [password1, password2]);

  const handleChange = (event) => {
    if (event.target.id === 'password1') {
      setPassword1(event.target.value);
    }
    else if (event.target.id === 'password2') {
      setPassword2(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    setInvalidCode(false);
    event.preventDefault();
    API.password_reset(code, password1)
      .then(response => {
        setRedirectLogin(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setInvalidCode(true);
        }
      });
  };

  if (redirectLogin) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <React.Fragment>
      <h1>Reset your password</h1>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password1">Password</label>
          <input
            className="form-control"
            id="password1"
            onChange={handleChange}
            required
            type="password"
            value={password1}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password2">Confirm password</label>
          <input
            className="form-control"
            id="password2"
            onChange={handleChange}
            required
            type="password"
            value={password2}
          />
        </div>

        <button className="btn btn-primary" disabled={submitDisabled}>Change password</button>
      </form>
      {invalidCode &&
      <div className="alert alert-danger" role="alert">
        This password reset code is either invalid or has expired already.
        <Link to="/password_request_reset">You can request a new password reset.</Link>
      </div>
      }
    </React.Fragment>
  );
};
