import React from 'react';

import * as API from './API';

class AddEmail extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: '',
    };
  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    API.email_add(this.state.email).then(response => {
      console.log(response);
      this.setState({email: ''});
    });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <h2>Add new</h2>

        <form onSubmit={this.handleSubmit}>
          <input className="form-control" type="email" value={this.state.email} onChange={this.handleChange} />
          <button className="btn btn-primary">Add email</button>
        </form>
      </div>
    );
  }
}

class EmailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.state = {
      emails: [],
    };

    API.email_list().then(response => {
      this.setState({emails: response.data});
    });
  }

  delete(email, event) {
    API.email_delete(email).then(response => {
      this.setState({
        emails: this.state.emails.filter(x => x.email !== email),
      });
    });
  }

  render() {
    const emailElements = this.state.emails.map(email =>
    <tr key={email.email}>
      <td>{email.email}</td>
      <td>{email.ts_create}</td>
      <td>{email.ts_confirm}</td>
      <td><button className="btn btn-danger" onClick={(e) => this.delete(email.email, e)}>DELETE</button></td>
    </tr>
    );
    return (
      <div>
        <h1>Emails</h1>

        <table className="table">
          <thead>
            <tr>
              <th>Address</th>
              <th>Created</th>
              <th>Confirmed</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {emailElements}
          </tbody>
        </table>

        <AddEmail />
      </div>
    );
  }
}

export default EmailsForm;
