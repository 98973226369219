import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as API from './API';

export default function ChangePasswordForm() {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword1, setNewPassword1] = React.useState('');
  const [newPassword2, setNewPassword2] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  React.useEffect(() => {
    setSubmitDisabled(newPassword1 !== newPassword2 || newPassword1.length === 0);
  }, [newPassword1, newPassword2]);

  const handleSubmit = (event) => {
    event.preventDefault();
    API.password_change(oldPassword, newPassword1).then(response => {
      setSuccess(true);
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="oldpassword">
            <FormattedMessage
              id="changepassword.old_password"
              defaultMessage="Old password"
            />
          </label>
          <input
            className="form-control"
            id="oldpassword"
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
          />
        </div>

        <div className="form-group">
          <label htmlFor="newpassword1">
            <FormattedMessage
              id="changepassword.new_password"
              defaultMessage="New password"
            />
          </label>
          <input
            className="form-control"
            id="newpassword1"
            onChange={(e) => setNewPassword1(e.target.value)}
            type="password"
          />
        </div>

        <div className="form-group">
          <label htmlFor="newpassword2">
            <FormattedMessage
              id="changepassword.new_password_confirm"
              defaultMessage="Confirm new password"
            />
          </label>
          <input
            className="form-control"
            id="newpassword2"
            onChange={(e) => setNewPassword2(e.target.value)}
            type="password"
          />
        </div>

        <button className="btn btn-primary" disabled={submitDisabled}>
          <FormattedMessage
            id="changepassword.change_password"
            defaultMessage="Change password"
          />
        </button>
      </form>
      {success &&
      <div className="alert alert-success" role="alert">
        Your password has been changed successfully!
      </div>
      }
    </React.Fragment>
  );
};
