import {
  ReplaySubject,
} from 'rxjs';

const accountSubjects = new Map(); // { accountId: ReplaySubject }

export function getAccountSubject(accountId) {
  if (!(accountSubjects.has(accountId))) {
    accountSubjects.set(accountId, new ReplaySubject(1));
  }
  return accountSubjects.get(accountId);
}

export function setAccount(accountId, account) {
  getAccountSubject(accountId).next(account);
}
