import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import * as API from './API';
import { RootContext } from './RootContextProvider';

export default function GDPRErasure() {
  const { setSession } = React.useContext(RootContext);
  const [redirect, setRedirect] = React.useState(false);

  const requestDelete = () => {
    API.gdpr_delete().then(response => {
      setSession(null);
      setRedirect(true);
    });
  };

  if (redirect) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <React.Fragment>
      <div className="alert alert-danger" role="alert">
        <FormattedMessage
          id="gdprerasure.info"
          defaultMessage="You may request deletion of all your personal data. All of your sessions will be logged out, and your account will be marked for deletion. After a month, your account will be deleted. To cancel the erasure request, you may log in at any time."
        />
      </div>
      <button className="btn btn-danger" onClick={requestDelete}>
        <FormattedMessage
          id="gdprerasure.request"
          defaultMessage="Request account deletion"
        />
      </button>
    </React.Fragment>
  );
};
