import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

export default function ChatLine({ onSend }) {
  const [text, setText] = React.useState('');

  const send = React.useCallback((event) => {
    event.preventDefault();
    setText(text => {
      if (text !== '') {
        onSend(text);
      }
      return '';
    });
  }, [onSend]);

  return (
    <React.Fragment>
      {/* padding for fixed-bottom */}
      <div style={{paddingBottom: '70px'}}></div>
      <form onSubmit={send}>
        <nav className="navbar fixed-bottom navbar-light bg-light">
          <div className="input-group">
            <input
              autoFocus
              className="form-control"
              onChange={e => setText(e.target.value)}
              type="text"
              value={text}
            />
            <div className="input-group-append">
              <button className="btn btn-primary">
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </nav>
      </form>
    </React.Fragment>
  );
}
