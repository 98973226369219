import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import * as API from './API';
import './UserBrowsing.css';
import UserProfileActionBar from './UserProfileActionBar';
import UserProfileCard from './UserProfileCard';
import UserSearch from './UserSearch';

export default function UserBrowsing() {
  const [criteria, setCriteria] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);

  const back = React.useCallback(() => {
    setIndex(i => Math.max(0, i - 1));
  }, []);

  const next = React.useCallback(() => {
    setIndex(i => i + 1);
  }, []);

  const handleSearchChange = React.useCallback((newCriteria) => {
    setCriteria(newCriteria);
  }, []);

  // when criteria changes, perform server search
  React.useEffect(() => {
    if (criteria !== null) {
      API.search(criteria).then(response => {
        setUsers(response.data);
      });
    }
  }, [criteria]);

  // users changes -> reset index
  React.useEffect(() => {
    setIndex(0);
  }, [users]);

  // index changes -> set selected user
  React.useEffect(() => {
    if (users.length > index) {
      setUser(users[index]);
    } else {
      setUser(null);
    }
  }, [index, users]);

  return (
    <div className="d-flex flex-column align-items-center">
      <button
        className="btn btn-light align-self-end"
        data-toggle="button"
        onClick={e => setSearchVisible(x => !x)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
      <div className={classNames('mb-3', 'w-75', {'d-none': !searchVisible})}>
        <UserSearch onChange={handleSearchChange} />
      </div>

      {user &&
      <UserProfileCard profile={user} />
      }
      {user === null &&
      <div className="alert alert-info" role="alert">
        No more users to browse. Adjust your search criteria or come back later!
      </div>
      }

      <UserProfileActionBar
        back={index !== 0 ? back : undefined}
        next={next}
        profile={user}
      />
    </div>
  );
};
