import React from 'react';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RootContext } from './RootContextProvider';

const url = `${window.location.origin}`.replace('http', 'ws') + '/api/ws';

class Client {
  constructor() {
    this.socket = null;
    this.account = null;
    this.allMessages = new Subject();
    this.groupMessages = (chatId) => {
      return this.allMessages.pipe(filter(msg => msg.chat_id === chatId));
    }
    this.privateChatMessages = (accountId) => {
      return this.allMessages.pipe(filter(msg => msg.private_chat_account_id === accountId));
    }
    this.session = localStorage.getItem('session');
  }

  connect() {
    this.socket = new WebSocket(url);
    this.socket.addEventListener('open', (event) => this.handleOpen(event));
    this.socket.addEventListener('message', (event) => this.handleMessage(event));
    this.socket.addEventListener('close', (event) => this.handleClose(event));
  }

  disconnect() {
    this.socket.close();
    this.socket = null;
  }

  handleOpen(event) {
    this.sendLogin();
  }

  handleMessage(event) {
    const json = JSON.parse(event.data);
    console.debug('Client.handleMessage', event, json);
    let [msgType, payload] = json;
    switch (msgType) {
      case 'account':
        this.account = payload;
        break;
      case 'msg':
        this.allMessages.next(payload);
        break;
      default:
        console.error('Unhandled message:', msgType, payload);
    }
  }

  handleClose(event) {
    setTimeout(() => this.connect(), 15000);
  }

  send(type, payload) {
    const json = [type, payload];
    const data = JSON.stringify(json);
    console.debug('Client.send', json, data);
    this.socket.send(data);
  }

  sendLogin() {
    this.send('login', {'session': this.session});
  }

  sendGroupMessage(chatId, text) {
    this.send('group-msg', {
      chat_id: chatId,
      text: text,
    });
  }

  sendPrivateMessage(accountId, text) {
    this.send('private-msg', {
      target_account_id: accountId,
      text: text,
    });
  }

  setSession(session) {
    let oldSession = this.session;
    this.session = session;
    if (oldSession !== session) {
      this.sendLogin();
    }
  }
};

export const client = new Client();

export function WebsocketComponent() {
  const { session } = React.useContext(RootContext);

  React.useEffect(() => {
    client.connect();
    return () => {
      client.disconnect();
    };
  }, []);

  React.useEffect(() => {
    client.setSession(session);
  }, [session]);

  // Render nothing
  return null;
}
