import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as API from './API';
import AccountInline from './AccountInline';
import { ProfileContext } from './ProfileContextProvider';

export default function ChatMemberList({ amAdmin, chatId, members }) {
  const { profile } = React.useContext(ProfileContext);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const removeMember = React.useCallback((accountId) => {
    API.chat_group_kick_member(chatId, accountId).then(response => {
      const index = members.findIndex(m => m.account_id === accountId);
      if (index !== -1) {
        members.splice(index, 1);
      }
      forceUpdate();
    });
  }, [chatId, members]);

  const setMemberAdmin = React.useCallback((accountId, admin) => {
    API.chat_group_set_admin(chatId, accountId, admin).then(response => {
      const member = members.find(m => m.account_id === accountId);
      member.admin = admin;
      forceUpdate();
    });
  }, [chatId, members]);

  return (
    <ul className="list-inline mt-2 text-muted">
      {members.map(member => (
        <li className="list-inline-item" key={member.account_id}>
          <AccountInline account={member} />
          {member.admin &&
          <span className="badge badge-secondary ml-1">
            <FormattedMessage
              id="chatmemberlist.admin"
              defaultMessage="Admin"
            />
          </span>
          }
          {amAdmin && profile && profile.id && member.account_id !== profile.id &&
          <span className="dropdown ml-1">
            <button
              className="btn btn-sm btn-light dropdown-toggle"
              data-toggle="dropdown"
              type="button"
            >
            </button>
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={e => removeMember(member.account_id)}>
                <FormattedMessage
                  id="chatmemberlist.remove"
                  defaultMessage="Remove"
                />
              </button>
              {!member.admin &&
              <button className="dropdown-item" onClick={e => setMemberAdmin(member.account_id, true)}>
                <FormattedMessage
                  id="chatmemberlist.make_admin"
                  defaultMessage="Make admin"
                />
              </button>
              }
              {member.admin &&
              <button className="dropdown-item" onClick={e => setMemberAdmin(member.account_id, false)}>
                <FormattedMessage
                  id="chatmemberlist.dismiss_admin"
                  defaultMessage="Dismiss as admin"
                />
              </button>
              }
            </div>
          </span>
          }
        </li>
      ))}
    </ul>
  );
}
