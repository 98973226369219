import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import UserText from './UserText';

export default function UserProfileCard({ profile }) {
  const [imageIndex, setImageIndex] = React.useState(0);

  // When profile changes, reset to first image
  React.useEffect(() => {
    setImageIndex(0);
  }, [profile]);

  const nextImage = React.useCallback(() => {
    setImageIndex(i => (i === profile.images.length - 1) ? 0 : i + 1);
  }, [profile]);

  const prevImage = React.useCallback(() => {
    setImageIndex(i => i === 0 ? Math.max(0, profile.images.length - 1) : i - 1);
  }, [profile]);

  const selectedImage = React.useCallback(() => {
    if (profile.images.length > imageIndex) {
      return profile.images[imageIndex];
    }
    return null;
  }, [imageIndex, profile]);

  const handleKeyDown = React.useCallback(event => {
    let handled = true;
    if (event.key === ' ') {
      // Previous image
      if (event.shiftKey) {
        prevImage();
      }
      else {
        nextImage();
      }
    }
    else {
      handled = false;
    }
    if (handled) {
      event.preventDefault();
    }
  }, [nextImage, prevImage]);

  // When handleKeyDown changes, (re)register event listener
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="card" style={{width: '20rem'}}>
      {selectedImage() &&
          /* eslint-disable-next-line jsx-a11y/alt-text */
          <img className="card-img-top card-img-top-custom" src={'/images/th/640x640/' + selectedImage().filename} />
      }
      {!selectedImage() &&
      <div className="card-img-top">
        <div className="card-img-top-custom" style={{backgroundColor: 'lightgray'}}>
        </div>
      </div>
      }
      <div className="btn-group btn-group-sm w-100" role="group">
        <button className="btn btn-light" onClick={prevImage} type="button">
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        </button>
        <button className="btn btn-light" onClick={nextImage} type="button">
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </button>
      </div>
      <div className="card-body">
        <h5 className="card-title">
          <Link to={`/profile/${profile.id}`}>
            {profile.name}
          </Link>
          &nbsp;
          <small className="text-muted">{profile.age}</small>
        </h5>
        <h6 className="card-subtitle mb-4 text-muted"><FontAwesomeIcon icon={faMapMarkerAlt} /> {profile.distance} km</h6>
        <div className="card-text"><blockquote className="blockquote"><UserText>{profile.text}</UserText></blockquote></div>
      </div>
    </div>
  );
}
