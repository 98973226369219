import React from 'react';
import { Link } from 'react-router-dom';

import LocaleSelection from './intl/LocaleSelection';
import SessionMenu from './SessionMenu';

export default function NavBar() {
  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggle">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarToggle">
        <Link className="navbar-brand" to="/">Pilke</Link>
        <ul className="navbar-nav ml-auto">
          <SessionMenu />
          <li className="nav-item">
            <LocaleSelection />
          </li>
        </ul>
      </div>
    </nav>
  );
};
