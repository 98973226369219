import React from 'react';

import { LocalizationContext } from './LocalizationProvider';

export default function LocaleSelection() {
  const { locale, setLocale } = React.useContext(LocalizationContext);

  return (
    <div>
      {locale === 'fi' &&
      <button className="btn" onClick={e => setLocale('en')}>
        <span aria-label="en" role="img">🇬🇧</span>
      </button>
      }
      {locale === 'en' &&
      <button className="btn" onClick={e => setLocale('fi')}>
        <span aria-label="fi" role="img">🇫🇮</span>
      </button>
      }
    </div>
  );
}
