import React from 'react';
import { Redirect } from 'react-router-dom';

import * as API from './API';

class ConfirmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.match.params.code,
      redirect: false,
    };

    API.confirm(this.state.code)
      .then(result => {
        this.setState({
          redirect: true,
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 404) {
            // already confirmed or invalid code
            this.setState({
              redirect: true,
            });
          }
        }
      })
    ;
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to="/login" />
      );
    }
    return (
      <div>Confirming code: {this.state.code}</div>
    );
  }
}

export default ConfirmPage;
