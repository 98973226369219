import React from 'react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faHeart,
  faThumbsDown,
  faThumbsUp,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import * as API from './API';
import { notify } from './NotificationService';

function UserProfileActionBar({ back, history, next, profile }) {
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const intl = useIntl();

  const like = React.useCallback((like) => {
    if (profile === null) {
      return;
    }
    API.like(profile.id, like).then(response => {
      profile.am_interested = like;
      profile.match = response.data.match;
      if (response.data.match) {
        notify(intl.formatMessage({id: 'userprofileactionbar.new_match', defaultMessage: 'New match!'}), `${profile.name} (${profile.age})`, () => {
          history.push(`/profile/${profile.id}`);
        });
      }
      // React does not render when props object property is changed
      forceUpdate();
    });
    if (next) {
      next();
    }
  }, [history, intl, next, profile]);

  const handleKeyDown = React.useCallback(event => {
    let handled = true;
    if (event.key === 'ArrowLeft') {
      like(false);
    }
    else if (event.key === 'ArrowRight') {
      like(true);
    }
    else if (event.key === 'Backspace' && back !== undefined) {
      back();
    }
    else {
      handled = false;
    }
    if (handled) {
      event.preventDefault();
    }
  }, [back, like]);

  // When handleKeyDown changes, (re)register event listener
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <React.Fragment>
      {/* padding for fixed-bottom */}
      <div style={{paddingBottom: '70px'}}></div>
      <nav className="navbar fixed-bottom navbar-light bg-light">
        <div className="form-inline" style={{width: '100%'}}>
          <div className="btn-group btn-group-lg" style={{width: '100%'}}>
            <button
              className="btn btn-light btn-lg"
              disabled={!back}
              onClick={back}
            >
              <FontAwesomeIcon icon={faUndoAlt} />
            </button>
            <Link
              className="btn btn-light btn-lg"
              disabled={profile === null}
              to={profile ? "/chats/private/" + profile.id : ''}
            >
              <FontAwesomeIcon icon={faComment} />
            </Link>
            <button
              className={classNames('btn', 'btn-lg', {'btn-danger': profile && profile.am_interested === false})}
              disabled={profile === null}
              onClick={e => like(false)}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </button>
            <button
              className={classNames('btn', 'btn-lg', {'btn-success': profile && profile.am_interested === true})}
              disabled={profile === null}
              onClick={e => like(true)}
            >
              <FontAwesomeIcon icon={(profile && profile.match) ? faHeart : faThumbsUp} />
            </button>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default withRouter(UserProfileActionBar);
