import React from 'react';
import { Link } from 'react-router-dom';

import { ProfileContext } from './ProfileContextProvider';

export default function ProfileWarning() {
  const { profile } = React.useContext(ProfileContext);

  const showWarning = (profile !== null && (profile.dob === null || profile.gender === null || profile.name === null));

  return showWarning ? (
    <div className="alert alert-dismissible alert-warning" role="alert">
      <strong>
        <Link className="alert-link" to="/profile">
          Fill in your profile!
        </Link>
      </strong>
      &nbsp;
      You are not visible in searches until you have done so.
      <button
        aria-label="Close"
        className="close"
        data-dismiss="alert"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  ) : null;
};
