import React from 'react';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import * as API from './API';
import Title from './Title';

export default function ChatsPage({ history }) {
  const [chats, setChats] = React.useState([]);

  const intl = useIntl();

  React.useEffect(() => {
    API.chat_list().then(response => {
      setChats(response.data);
    });
  }, []);

  const newGroupChat = () => {
    const name = prompt(intl.formatMessage({id: 'chats.chat_name', defaultMessage: 'Chat name'}));
    if (name !== null) {
      API.chat_create(name).then(response => {
        const pathname = `/chats/public/${response.data.chat_id}`;
        history.push(pathname);
      });
    }
  };

  return (
    <Title title={intl.formatMessage({id: 'chats.title', defaultMessage: 'Chats'})}>
      <div className="align-items-center d-flex justify-content-between">
        <h1>
          <FormattedMessage
            id="chats.title"
            defaultMessage="Chats"
          />
        </h1>

        <div>
          <button className="btn btn-secondary" onClick={newGroupChat} type="button">
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;
            <FormattedMessage
              id="chats.new_group_chat"
              defaultMessage="New group chat"
            />
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="chats.type"
                defaultMessage="Type"
              />
            </th>
            <th>
              #
            </th>
            <th>
              <FormattedMessage
                id="chats.name"
                defaultMessage="Name"
              />
            </th>
            <th>
              <FormattedMessage
                id="chats.last_message"
                defaultMessage="Last message"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {chats.map(chat => (
            <tr key={chat.chat_id}>
              <td>
                <FontAwesomeIcon icon={ chat.private ? faUserFriends : faUsers } />
              </td>
              <td>
                {chat.members.length + 1}
              </td>
              <td>
                <Link to={chat.private ? `/chats/private/${chat.members[0]}` : `/chats/public/${chat.chat_id}`}>
                  {chat.name}
                </Link>
              </td>
              <td>
                <FontAwesomeIcon icon={chat.members.includes(chat.last_message_account_id) ? faAngleDoubleLeft : faAngleDoubleRight} />
                &nbsp;
                {chat.last_message_text}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Title>
  );
}
