import React from 'react';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';

import { ProfileContext } from './ProfileContextProvider';

export default function UserSearch({ onChange }) {
  const { profile } = React.useContext(ProfileContext);
  const intl = useIntl();

  const [distance, setDistance] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [maxAge, setMaxAge] = React.useState(null);
  const [minAge, setMinAge] = React.useState(null);
  const [status, setStatus] = React.useState('');
  const [waiting, setWaiting] = React.useState(true);

  // Default to searching opposite gender
  React.useEffect(() => {
    if (profile && profile.gender !== null) {
      setGender(profile.gender === 'M' ? 'F' : 'M');
      setWaiting(false);
    }
  }, [profile]);

  // Call onChange callback when any criteria changes
  React.useEffect(() => {
    if (waiting) {
      return;
    }
    const criteria = {
      distance: distance !== '' ? parseInt(distance, 10) : null,
      gender: gender !== "" ? gender : null,
      maxAge: maxAge !== null ? parseInt(maxAge, 10) : maxAge,
      minAge: minAge !== null ? parseInt(minAge, 10) : minAge,
      status: status !== "" ? status : null,
    };
    onChange(criteria);
  }, [distance, gender, maxAge, minAge, onChange, status, waiting]);

  return (
    <div className="card">
      <div className="card-header">
        <FormattedMessage
          id="usersearch.criteria"
          defaultMessage="Search criteria"
        />
      </div>
      <div className="card-body">
        <form>
          <div className="form-group row">
            <label className="col-form-label col-sm-2" htmlFor="gender">
              <FormattedMessage
                id="usersearch.gender"
                defaultMessage="Gender"
              />
            </label>
            <div className="col-sm-10" onChange={e => setGender(e.target.value)}>
              <div className="form-check form-check-inline">
                <input checked={gender === ""} className="form-check-input" id="gendernull" name="gender" onChange={() => null} type="radio" value="" />
                <label className="form-check-label" htmlFor="gendernull">
                  <FormattedMessage
                    id="usersearch.gender_any"
                    defaultMessage="Any"
                  />
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input checked={gender === "M"} className="form-check-input" id="gendermale" name="gender" onChange={() => null} type="radio" value="M" />
                <label className="form-check-label" htmlFor="gendermale">
                  <FormattedMessage
                    id="usersearch.gender_male"
                    defaultMessage="Male"
                  />
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input checked={gender === "F"} className="form-check-input" id="genderfemale" name="gender" onChange={() => null} type="radio" value="F" />
                <label className="form-check-label" htmlFor="genderfemale">
                  <FormattedMessage
                    id="usersearch.gender_female"
                    defaultMessage="Female"
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-sm-2">
              <FormattedMessage
                id="usersearch.age"
                defaultMessage="Age"
              />
            </label>
            <div className="col-sm-5">
              <input
                className="form-control"
                min="0"
                name="minAge"
                onChange={e => setMinAge(e.target.value)}
                placeholder={intl.formatMessage({id: 'usersearch.age_minimum', defaultMessage: 'Minimum'})}
                type="number"
              />
            </div>
            <div className="col-sm-5">
              <input
                className="form-control"
                min="0"
                name="maxAge"
                onChange={e => setMaxAge(e.target.value)}
                placeholder={intl.formatMessage({id: 'usersearch.age_maximum', defaultMessage: 'Maximum'})}
                type="number"
              />
            </div>
          </div>

          <div className="form-group form-row">
            <label className="col-form-label col-sm-2" htmlFor="distance">
              <FormattedMessage
                id="usersearch.distance"
                defaultMessage="Distance"
              />
            </label>
            <div className="col-auto">
              <input
                className="form-control"
                id="distance"
                min="1"
                type="number"
                onChange={e => setDistance(e.target.value)}
                placeholder={intl.formatMessage({id: 'usersearch.kilometers', defaultMessage: 'Kilometers'})}
                value={distance}
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-light"
                onClick={e => setDistance('')}
                type="button"
              >x</button>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-sm-2" htmlFor="status">
              <FormattedMessage
                id="usersearch.status"
                defaultMessage="Status"
              />
            </label>
            <select id="status" onChange={e => setStatus(e.target.value)} value={status}>
              <option value="">{intl.formatMessage({id: 'usersearch.status_all', defaultMessage: 'All'})}</option>
              <option value="NEW">{intl.formatMessage({id: 'usersearch.status_new', defaultMessage: 'New'})}</option>
              <option value="UPDATED">{intl.formatMessage({id: 'usersearch.status_updated', defaultMessage: 'Updated'})}</option>
              <option value="LIKE">{intl.formatMessage({id: 'usersearch.status_like', defaultMessage: 'Liked'})}</option>
              <option value="PASS">{intl.formatMessage({id: 'usersearch.status_pass', defaultMessage: 'Passed'})}</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};
