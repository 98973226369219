import React from 'react';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { Link } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';
import GDPRErasure from './GDPRErasure';
import GDPRPortability from './GDPRPortability';
import Title from './Title';

export default function AccountPage() {
  const intl = useIntl();

  return (
    <Title title={intl.formatMessage({id: 'account.title', defaultMessage: 'Account'})}>
      <h1>
        <FormattedMessage
          id="account.title"
          defaultMessage="Account"
        />
      </h1>

      <h2>
        <FormattedMessage
          id="account.change_password"
          defaultMessage="Change password"
        />
      </h2>

      <ChangePasswordForm />

      <h2>
        <FormattedMessage
          id="account.sessions"
          defaultMessage="Sessions"
        />
      </h2>

      <p>
        <FormattedMessage
          id="account.sessions_link"
          defaultMessage="See the <Link>sessions page</Link>."
          values={{
            Link: msg => <Link to="/sessions">{msg}</Link>
          }}
        />
      </p>

      <h2>
        <FormattedMessage
          id="account.export_account_data"
          defaultMessage="Export account data"
        />
      </h2>

      <GDPRPortability />

      <h2>
        <FormattedMessage
          id="account.delete_account"
          defaultMessage="Delete account"
        />
      </h2>

      <GDPRErasure />
    </Title>
  );
};
