const messages = {
  'account.change_password': 'Muuta salasana',
  'account.delete_account': 'Poista tunnus',
  'account.export_account_data': 'Vie tunnuksen tiedot',
  'account.sessions': 'Istunnot',
  'account.sessions_link': 'Katso <Link>istunnot-sivu</Link>.',
  'account.title': 'Tunnus',
  'changepassword.change_password': 'Vaihda salasana',
  'changepassword.new_password': 'Uusi salasana',
  'changepassword.new_password_confirm': 'Vahvista uusi salasana',
  'changepassword.old_password': 'Vanha salasana',
  'chatgroup.created': 'Luotu {date}',
  'chatgroup.joined': 'liittyi',
  'chatgroup.leave_chat': 'Jätä keskustelu',
  'chatgroup.parted': 'lähti',
  'chatgroup.title': 'Ryhmäkeskustelu: {name}',
  'chatgroup.rename': 'Uusi nimi ryhmälle',
  'chatmemberlist.admin': 'Ylläpitäjä',
  'chatmemberlist.dismiss_admin': 'Erota ylläpitäjä',
  'chatmemberlist.make_admin': 'Tee ylläpitäjäksi',
  'chatmemberlist.remove': 'Poista',
  'chatprivate.title': 'Keskustelu: {name}',
  'chats.chat_name': 'Keskustelun nimi',
  'chats.last_message': 'Viimeinen viesti',
  'chats.name': 'Nimi',
  'chats.new_group_chat': 'Uusi ryhmäkeskustelu',
  'chats.title': 'Keskustelut',
  'chats.type': 'Tyyppi',
  'gdprerasure.info': 'Voit pyytää henkilökohtaisten tietojesi poistamista. Kaikki istuntosi kirjataan ulos, ja tunnuksesi merkitään poistettavaksi. Kuukauden kuluttua tunnuksesi poistetaan. Voit peruuttaa poistopyynnön milloin vain kirjautumalla sisään.',
  'gdprerasure.request': 'Lähetä tunnuksen poistopyyntö',
  'gdprportability.download': 'Tallenna tiedot',
  'gdprportability.info': 'Voit tallentaa kopion henkilökohtaisista tiedoistasi. Olet vastuussa tietojen turvallisesta säilytyksestä niiden lataamisen jälkeen.',
  'images.delete': 'Poista',
  'images.title': 'Kuvat',
  'images.upload': 'Lähetä',
  'images.upload_new_image': 'Lähetä uusi kuva',
  'notificationcomponent.new_message': 'Uusi viesti',
  'profile.disclaimer_visibility': 'Kaikki tiedot joita syötät tähän ovat julkisia.',
  'profile.dob': 'Syntymäpäivä',
  'profile.gender': 'Sukupuoli',
  'profile.gender_female': 'Nainen',
  'profile.gender_male': 'Mies',
  'profile.gender_select': 'Valitse',
  'profile.header': 'Profiili',
  'profile.name': 'Nimi',
  'profile.profile_text': 'Profiiliteksti',
  'profile.public_profile_link': 'Katso <Link>julkinen profiilisi</Link>.',
  'profile.save': 'Tallenna',
  'profile.saved': 'Profiilisi on tallennettu.',
  'profile.title': 'Profiili',
  'sessionmenu.account': 'Tunnus',
  'sessionmenu.chats': 'Keskustelut',
  'sessionmenu.images': 'Kuvat',
  'sessionmenu.log_out': 'Kirjaudu ulos',
  'sessionmenu.profile': 'Profiili',
  'sessions.agent': 'Selain',
  'sessions.date_created': 'Luontihetki',
  'sessions.date_usage': 'Käyttöhetki',
  'sessions.id': 'ID',
  'sessions.ip_created': 'IP luotu',
  'sessions.ip_usage': 'IP käytetty',
  'sessions.title': 'Istunnot',
  'userprofileactionbar.new_match': 'Tykkäsitte toisistanne!',
  'usersearch.age': 'Ikä',
  'usersearch.age_maximum': 'Enintään',
  'usersearch.age_minimum': 'Vähintään',
  'usersearch.criteria': 'Hakuehdot',
  'usersearch.distance': 'Etäisyys',
  'usersearch.gender': 'Sukupuoli',
  'usersearch.gender_any': 'Kaikki',
  'usersearch.gender_female': 'Nainen',
  'usersearch.gender_male': 'Mies',
  'usersearch.kilometers': 'Kilometriä',
  'usersearch.status': 'Tila',
  'usersearch.status_all': 'Kaikki',
  'usersearch.status_like': 'Tykätyt',
  'usersearch.status_new': 'Uudet',
  'usersearch.status_pass': 'Ohitetut',
  'usersearch.status_updated': 'Päivitetyt',
};

export default messages;
