if (Notification.permission !== 'denied') {
  Notification.requestPermission();
}

export function notify(title, body, onclick) {
  // Native notifications
  if (Notification.permission === 'granted') {
    const notification = new Notification(title, {body: body});
    notification.onclick = onclick;
  }
}
