import React from 'react';

import AccountInline from './AccountInline';
import { getAccountSubject } from './AccountNamesService';

export default function AccountInlineFromId({ id }) {
  const [account, setAccount] = React.useState({account_id: id});

  React.useEffect(() => {
    let subscription = getAccountSubject(id).subscribe(account => {
      setAccount(account);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  return (
    <AccountInline account={account} />
  );
}
