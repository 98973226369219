import React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl';

import * as API from './API';
import Title from './Title';

export default function SessionsForm() {
  const [sessions, setSessions] = React.useState([]);

  const intl = useIntl();

  const deleteSession = (session_id) => {
    API.session_delete(session_id).then(response => {
      setSessions(sessions.filter(s => s.session_id !== session_id));
    });
  };

  React.useEffect(() => {
    API.session_list().then(response => {
      setSessions(response.data);
    });
  }, []);

  const sessionElements = sessions.map(s =>
  <tr key={s.session_id}>
    <td>{s.session_id}</td>
    <td>{s.ip_create}</td>
    <td>{s.ip_last}</td>
    <td>
      <FormattedDate value={new Date(s.ts_create)} />
      &nbsp;
      <FormattedTime value={new Date(s.ts_create)} />
    </td>
    <td>
      <FormattedDate value={new Date(s.ts_usage)} />
      &nbsp;
      <FormattedTime value={new Date(s.ts_usage)} />
    </td>
    <td>{s.agent}</td>
    <td><button className="btn btn-danger" onClick={x => deleteSession(s.session_id)} type="button">&times;</button></td>
  </tr>
  );

  return (
    <Title title={intl.formatMessage({id: 'sessions.title', defaultMessage: 'Sessions'})}>
      <h1>
        <FormattedMessage
          id="sessions.title"
          defaultMessage="Sessions"
        />
      </h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="sessions.id"
                defaultMessage="ID"
              />
            </th>
            <th>
              <FormattedMessage
                id="sessions.ip_created"
                defaultMessage="IP created"
              />
            </th>
            <th>
              <FormattedMessage
                id="sessions.ip_usage"
                defaultMessage="IP usage"
              />
            </th>
            <th>
              <FormattedMessage
                id="sessions.date_created"
                defaultMessage="Date created"
              />
            </th>
            <th>
              <FormattedMessage
                id="sessions.date_usage"
                defaultMessage="Date usage"
              />
            </th>
            <th>
              <FormattedMessage
                id="sessions.agent"
                defaultMessage="Agent"
              />
            </th>
            <th>X</th>
          </tr>
        </thead>
        <tbody>
          {sessionElements}
        </tbody>
      </table>
    </Title>
  );
}
