import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RootContext } from './RootContextProvider';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { session } = React.useContext(RootContext);

  return (
    <Route
      {...rest}
      render={props =>
        session ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
