import React from 'react';

import * as API from './API';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.email = React.createRef();
    this.password = React.createRef();
    this.state = { showAlert: false };
  }

  handleSubmit(event) {
    let email = this.email.current.value;
    let password = this.password.current.value;
    API.register(email, password)
      .then(response => {
        this.setState({showAlert: true});
      });
    event.preventDefault();
  }

  render() {
    return (
      <React.Fragment>
        <h1>Register</h1>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-form-label col-sm-2" htmlFor="email">
              Email:
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                id="email"
                type="email"
                ref={this.email}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-2" htmlFor="password">
              Password:
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                id="password"
                type="password"
                ref={this.password}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-10">
              <button className="btn btn-primary">Register</button>
            </div>
          </div>
        </form>
        {this.state.showAlert &&
        <div className="alert alert-info" role="alert">
          A confirmation email has been sent to the address you provided.
          Please check your email and open the link.
        </div>
        }
      </React.Fragment>
    );
  }
}

export default RegisterPage;
