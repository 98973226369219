import React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleUp,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import * as AccountNamesService from './AccountNamesService';
import * as API from './API';
import ChatLine from './ChatLine';
import ChatMemberList from './ChatMemberList';
import ChatMessages from './ChatMessages';
import Title from './Title';
import { client } from './Websocket';

export default function ChatGroup({ history, match }) {
  const chatId = parseInt(match.params.chatId, 10);

  const [info, setInfo] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const intl = useIntl();

  const initialFetch = React.useCallback(() => {
    API.chat_group_info_get(chatId)
      .then(response => {
        setInfo(response.data);
        for (let m of response.data.members) {
          AccountNamesService.setAccount(m.account_id, m);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          API.chat_group_join(chatId).then(response => {
            initialFetch();
          });
        }
      });
    API.chat_group_messages_get(chatId, {limit: 10}).then(response => {
      setMessages(response.data.reverse());
    });
  }, [chatId]);

  const leaveChat = React.useCallback(() => {
    API.chat_group_part(chatId).then(response => {
      history.push('/chats');
    });
  }, [chatId, history]);

  const loadMoreMessages = React.useCallback(() => {
    const before_msg_id = messages[0].msg_id;
    API.chat_group_messages_get(chatId, {
      before_msg_id: before_msg_id,
      limit: 100,
    }).then(response => {
      setMessages(response.data.reverse().concat(messages));
    });
  }, [chatId, messages]);

  // Initial fetch of chat info & messages
  React.useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  // Listen to messages from websocket client
  React.useEffect(() => {
    const subscription = client.groupMessages(chatId).subscribe(msg => {
      setMessages(messages => messages.concat([msg]));
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [chatId]);

  const renameChat = React.useCallback(() => {
    const newName = prompt(intl.formatMessage({id: 'chatgroup.rename', defaultMessage: 'New group name'}), info.name);
    if (newName !== null && newName.length > 0 && newName !== info.name) {
      API.chat_group_rename(chatId, newName).then(response => {
        info.name = newName;
        forceUpdate();
      });
    }
  }, [chatId, info, intl]);

  const send = React.useCallback((text) => {
    client.sendGroupMessage(chatId, text);
  }, [chatId]);

  return (
    <div className="d-flex flex-column" style={{height: '100vh'}}>
      {info &&
      <React.Fragment>
        <Title title={intl.formatMessage({id: 'chatgroup.title', defaultMessage: 'Group chat: {name}'}, {name: info.name})} />
        <h3>
          <FormattedMessage
            id="chatgroup.title"
            defaultMessage="Group chat: {name}"
            values={{
              name: info.name
            }}
          />
          <button className="btn btn-light btn-sm ml-2" onClick={renameChat}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          &nbsp;
          <small className="text-muted">
            <FormattedMessage
              id="chatgroup.created"
              defaultMessage="Created on {date}"
              values={{
                date: <FormattedDate value={info.ts_create} />
              }}
            />
          </small>
        </h3>
        <div>
          <button className="btn btn-secondary" onClick={leaveChat}>
            <FormattedMessage
              id="chatgroup.leave_chat"
              defaultMessage="Leave chat"
            />
          </button>
        </div>
        <ChatMemberList amAdmin={info.am_admin} chatId={chatId} members={info.members} />
      </React.Fragment>
      }

      <div className="d-flex flex-column flex-grow-1" style={{'overflowY': 'scroll'}}>
        <div className="align-self-center">
          <button className="btn btn-light" onClick={loadMoreMessages}>
            <FontAwesomeIcon icon={faAngleDoubleUp} />
          </button>
        </div>
        <ChatMessages messages={messages} />
      </div>

      <ChatLine onSend={send} />
    </div>
  );
}
