import React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
} from 'react-intl';

import AccountInlineFromId from './AccountInlineFromId';
import UserText from './UserText';

export default function ChatMessages({ messages }) {
  const bottomOfChat = React.useRef();
  const [lastMsgId, setLastMsgId] = React.useState(null);

  React.useEffect(() => {
    if (messages.length > 0) {
      setLastMsgId(messages[messages.length-1].msg_id);
    }
  }, [messages]);

  // Scroll to bottom when a new message is received
  React.useEffect(() => {
    bottomOfChat.current.scrollIntoView();
  }, [bottomOfChat, lastMsgId]);

  return (
    <React.Fragment>
      <div className="chat-messages">
        {messages.map(message => (
          <div className="chat-message" key={message.msg_id}>
            <div className="who">
              <AccountInlineFromId id={message.account_id} />
              &nbsp;
              {message.msg_type === 'J' &&
              <FormattedMessage
                id="chatgroup.joined"
                defaultMessage="joined"
              />
              }
              {message.msg_type === 'P' &&
              <FormattedMessage
                id="chatgroup.parted"
                defaultMessage="parted"
              />
              }
            </div>
            {message.text &&
            <div className="text">
              <UserText>
                {message.text}
              </UserText>
            </div>
            }
            <div className="ts">
              <FormattedDate value={new Date(message.ts_sent)} />
              &nbsp;
              <FormattedTime value={new Date(message.ts_sent)} />
            </div>
          </div>
        ))}
      </div>
      <div ref={bottomOfChat} />
    </React.Fragment>
  );
}
